import tethysLogo from '../../assets/tethys-logo.svg'
import ethLogo from '../../assets/ether.png'

export const tethysList = [
  {
    chainId: 250,
    address: '0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
    decimals: 18,
    name: 'Tomb',
    symbol: 'TOMB',
    logoURI: 'https://swap.tomb.com/images/tokens/TOMB.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 250,
    address: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    decimals: 6,
    name: 'USD Coin',
    symbol: 'USDC',
    logoURI: 'https://swap.tomb.com/images/tokens/USDC.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 250,
    address: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    decimals: 18,
    name: 'Fantom',
    symbol: 'FTM',
    logoURI: 'https://swap.tomb.com/images/tokens/FTM.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 250,
    address: '0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37',
    decimals: 18,
    name: 'TSHARE',
    symbol: 'TSHARE',
    logoURI: 'https://swap.tomb.com/images/tokens/TSHARE.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 250,
    address: '0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae',
    decimals: 18,
    name: 'BASED',
    symbol: 'BASED',
    logoURI: 'https://swap.tomb.com/images/tokens/BASED.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 250,
    address: '0x49C290Ff692149A4E16611c694fdED42C954ab7a',
    decimals: 18,
    name: 'BSHARE',
    symbol: 'BSHARE',
    logoURI: 'https://swap.tomb.com/images/tokens/BSHARE.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 250,
    address: '0x24248CD1747348bDC971a5395f4b3cd7feE94ea0',
    decimals: 18,
    name: 'TBOND',
    symbol: 'TBOND',
    logoURI: 'https://swap.tomb.com/images/tokens/TBOND.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 250,
    address: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
    decimals: 8,
    name: 'Bitcoin',
    symbol: 'BTC',
    logoURI: 'https://swap.tomb.com/images/tokens/BTC.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 250,
    address: '0x09e145A1D53c0045F41aEEf25D8ff982ae74dD56',
    decimals: 0,
    name: 'ZooCoin',
    symbol: 'ZOO',
    logoURI: 'https://swap.tomb.com/images/tokens/ZOO.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 250,
    address: '0xbf60e7414EF09026733c1E7de72E7393888C64DA',
    decimals: 18,
    name: 'LIF3',
    symbol: 'LIF3',
    logoURI: 'https://swap.tomb.com/images/tokens/LIF3.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 250,
    address: '0xCbE0CA46399Af916784cADF5bCC3aED2052D6C45',
    decimals: 18,
    name: 'LSHARE',
    symbol: 'LSHARE',
    logoURI: 'https://swap.tomb.com/images/tokens/LSHARE.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 250,
    address: '0xfB98B335551a418cD0737375a2ea0ded62Ea213b',
    decimals: 18,
    name: 'MAI',
    symbol: 'MAI',
    logoURI: 'https://swap.tomb.com/images/tokens/MAI.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 250,
    address: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
    decimals: 18,
    name: 'Ethereum',
    symbol: 'ETH',
    logoURI: 'https://swap.tomb.com/images/tokens/WETH.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 250,
    address: '0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    decimals: 18,
    name: 'fUSDT',
    symbol: 'fUSDT',
    logoURI: 'https://swap.tomb.com/images/tokens/FUSDT.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 250,
    address: '0x82f0B8B456c1A451378467398982d4834b6829c1',
    decimals: 18,
    name: 'MIM',
    symbol: 'MIM',
    logoURI: 'https://swap.tomb.com/images/tokens/MIM.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 250,
    address: '0xAd84341756Bf337f5a0164515b1f6F993D194E1f',
    decimals: 18,
    name: 'FUSD',
    symbol: 'FUSD',
    logoURI: 'https://swap.tomb.com/images/tokens/FUSD.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 250,
    address: '0xc60D7067dfBc6f2caf30523a064f416A5Af52963',
    decimals: 18,
    name: 'TREEB',
    symbol: 'TREEB',
    logoURI: 'https://swap.tomb.com/images/tokens/TREEB.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 250,
    address: '0x511D35c52a3C244E7b8bd92c0C297755FbD89212',
    decimals: 18,
    name: 'AVAX',
    symbol: 'AVAX',
    logoURI: 'https://swap.tomb.com/images/tokens/AVAX.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 250,
    address: '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    decimals: 18,
    name: 'DAI',
    symbol: 'DAI',
    logoURI: 'https://swap.tomb.com/images/tokens/DAI.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 250,
    address: '0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454',
    decimals: 18,
    name: 'BNB',
    symbol: 'BNB',
    logoURI: 'https://swap.tomb.com/images/tokens/BNB.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 250,
    address: '0x1E4F97b9f9F913c46F1632781732927B9019C68b',
    decimals: 18,
    name: 'CRV',
    symbol: 'CRV',
    logoURI: 'https://swap.tomb.com/images/tokens/CRV.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 250,
    address: '0xb3654dc3D10Ea7645f8319668E8F54d2574FBdC8',
    decimals: 18,
    name: 'LINK',
    symbol: 'LINK',
    logoURI: 'https://swap.tomb.com/images/tokens/LINK.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 250,
    address: '0x5f0456F728E2D59028b4f5B8Ad8C604100724C6A',
    decimals: 18,
    name: 'L3USD',
    symbol: 'L3USD',
    logoURI: 'https://swap.tomb.com/images/tokens/L3USD.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
]
