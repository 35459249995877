import React from 'react'

export const BridgeIcon: React.FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6C2 5.44772 2.44772 5 3 5H13C13.5523 5 14 5.44772 14 6V13H11.5999V8.6668C11.5999 8.00406 11.0627 7.4668 10.3999 7.4668C9.73717 7.4668 9.19992 8.00405 9.19992 8.6668V13H6.80004V8.6668C6.80004 8.00406 6.26278 7.4668 5.60004 7.4668C4.9373 7.4668 4.40004 8.00405 4.40004 8.6668V13H2V6Z"
        fill="currentColor"
      />
      <rect width="12" height="1" rx="0.5" transform="matrix(1 0 0 -1 2 4)" fill="currentColor" fillOpacity="0.4" />
    </svg>
  )
}
