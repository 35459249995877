import React from 'react'

export const LiquidityIcon: React.FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8754 8.44523L9.28571 3L5.69607 8.44523C5.27429 9.08484 5.03502 9.82527 5.00356 10.5883C4.9721 11.3513 5.1496 12.1085 5.51732 12.78C5.88504 13.4514 6.42931 14.0122 7.09262 14.4029C7.75594 14.7937 8.51366 15 9.28571 15C10.0578 15 10.8155 14.7937 11.4788 14.4029C12.1421 14.0122 12.6864 13.4514 13.0541 12.78C13.4218 12.1085 13.5993 11.3513 13.5679 10.5883C13.5364 9.82527 13.2971 9.08484 12.8754 8.44523Z"
        fill="currentColor"
      />
      <path
        d="M6.71436 10.7144C6.71436 11.052 6.78087 11.3864 6.91009 11.6984C7.03932 12.0104 7.22873 12.2939 7.46751 12.5326C7.70629 12.7714 7.98976 12.9608 8.30174 13.09C8.61372 13.2193 8.9481 13.2858 9.28578 13.2858"
        stroke="#747B8B"
        strokeLinecap="round"
      />
    </svg>
  )
}
