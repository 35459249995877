import React from 'react'

export const FarmIcon: React.FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9926 5.61768C13.9926 5.23909 13.7788 4.89295 13.4403 4.72348L7.99998 2L2.55279 4.72359C2.214 4.89299 2 5.23925 2 5.61802V12.9926C2 13.5449 2.44772 13.9926 3 13.9926H12.9926C13.5449 13.9926 13.9926 13.5449 13.9926 12.9926V5.61768Z"
        fill="currentColor"
      />
      <path d="M11 8H5V14H11V8Z" fill="#747B8B" />
      <path d="M11 8H8V14H11V8Z" fill="#A6A6A6" />
    </svg>
  )
}
