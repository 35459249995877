import React from 'react'

const Logo = () => {
  return (
    <svg width="128" height="55" viewBox="0 0 128 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_3_12)">
        <path
          d="M67.292 10.1851V35.6889C67.292 36.764 67.4819 37.505 67.8617 37.9121C68.2414 38.3191 68.8653 38.5244 69.7331 38.5279C70.1803 38.5348 70.627 38.4985 71.0671 38.4194C71.3634 38.3698 71.6505 38.2762 71.9189 38.1417V41.4916C71.3742 41.7405 70.8017 41.924 70.2135 42.0381C69.5806 42.1675 68.9363 42.2331 68.2903 42.2339C66.3367 42.2339 64.9003 41.7325 63.9813 40.7298C63.0622 39.7271 62.6033 38.1791 62.6045 36.0858V10.1851H67.292Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter1_d_3_12)">
        <path
          d="M81.2208 41.9598H76.5422V20.162H81.2208V41.9598ZM78.8993 16.5059C77.8779 16.5059 77.0981 16.2513 76.56 15.7423C76.0243 15.235 75.7511 14.5158 75.7511 13.5849C75.7511 12.654 76.0189 11.9438 76.56 11.4489C77.1011 10.9541 77.8779 10.7084 78.8993 10.7084C79.891 10.7084 80.6565 10.9553 81.1958 11.4489C81.7351 11.9426 82.0047 12.6546 82.0047 13.5849C82.0047 14.5164 81.7351 15.2356 81.1958 15.7423C80.6565 16.249 79.891 16.5035 78.8993 16.5059Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter2_d_3_12)">
        <path
          d="M97.8032 10.0481C98.5925 10.0481 99.4675 10.1727 99.9907 10.3827V14.11C99.4227 13.9622 98.8412 13.8722 98.255 13.8412C97.0585 13.8412 96.4549 14.1171 95.7692 14.6689C95.0835 15.2207 94.7424 16.1232 94.7424 17.3727V20.55L94.8728 21.7266V41.9598H90.1941V17.0239C90.1772 16.013 90.3474 15.0078 90.6959 14.0584C91.0121 13.2137 91.53 12.4586 92.2049 11.8583C92.9105 11.2429 93.7379 10.7821 94.6335 10.5055C95.6596 10.1891 96.7291 10.0348 97.8032 10.0481ZM99.4282 23.8234H86.0816V20.162H99.4354L99.4282 23.8234Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter3_d_3_12)">
        <path
          d="M112.861 17.1983C114.726 17.1983 116.293 17.495 117.562 18.0883C118.832 18.6816 119.793 19.4737 120.448 20.4646C121.098 21.4307 121.441 22.5695 121.432 23.7326C121.451 24.5889 121.303 25.4406 120.996 26.2407C120.732 26.9081 120.347 27.5213 119.859 28.0491C119.415 28.5275 118.879 28.9129 118.284 29.183C117.748 29.436 117.17 29.587 116.578 29.628V29.6707C117.942 29.6674 119.264 30.1375 120.318 31.0004C120.837 31.4356 121.256 31.9787 121.543 32.5917C121.861 33.2892 122.017 34.0489 122.002 34.8149C122.002 36.3575 121.601 37.6872 120.798 38.8038C119.951 39.957 118.799 40.8535 117.471 41.3937C116.057 42.0025 114.431 42.3075 112.594 42.3087C110.349 42.3087 108.374 41.8215 106.669 40.8473C104.965 39.873 103.715 38.3838 102.919 36.3795L107.205 34.5052C107.525 35.8105 108.13 36.8192 109.019 37.5312C109.909 38.2432 111.099 38.5992 112.591 38.5992C113.482 38.6244 114.369 38.4612 115.193 38.1203C115.864 37.8357 116.44 37.3673 116.855 36.7693C117.256 36.1614 117.463 35.4462 117.446 34.7188C117.446 33.5001 117.06 32.6285 116.287 32.1039C115.515 31.5794 114.324 31.3178 112.716 31.319C112.478 31.319 112.215 31.3261 111.928 31.3403C111.636 31.3564 111.344 31.3777 111.053 31.4062V28.0527C111.271 28.0816 111.49 28.0964 111.709 28.0972H112.321C113.869 28.0972 115.014 27.8005 115.755 27.2072C116.497 26.6139 116.868 25.7055 116.87 24.482C116.87 23.3488 116.505 22.4694 115.777 21.8441C115.048 21.2187 114.018 20.906 112.686 20.906C111.314 20.906 110.243 21.211 109.471 21.8209C108.7 22.4309 108.197 23.3025 107.962 24.4357L103.764 22.9103C104.114 21.7382 104.745 20.6686 105.602 19.7935C106.486 18.9123 107.564 18.2481 108.75 17.8533C110.072 17.4066 111.461 17.1857 112.857 17.2001"
          fill="white"
        />
      </g>
      <g filter="url(#filter4_d_3_12)">
        <path
          d="M17.936 35.1495L14.3378 45H8.05551L22.2772 7.42613H25.9326H29.588L43.8078 45H37.3542L25.8736 12.6629L19.6485 30.1976L17.936 35.1495Z"
          fill="white"
        />
        <path d="M9.96972 40.0463H5V45H9.96972V40.0463Z" fill="white" />
        <path
          d="M29.8985 8.95337C29.8985 11.1374 21.9662 11.1374 21.9662 8.95337C21.9662 7.90487 22.3841 6.89932 23.1279 6.15792C23.8717 5.41652 24.8805 5 25.9324 5C26.9842 5 27.993 5.41652 28.7368 6.15792C29.4806 6.89932 29.8985 7.90487 29.8985 8.95337"
          fill="white"
        />
        <path d="M46.8828 40.0463H41.913V45H46.8828V40.0463Z" fill="white" />
      </g>
      <defs>
        <filter
          id="filter0_d_3_12"
          x="57.6045"
          y="10.1851"
          width="19.3144"
          height="42.0488"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_12" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_12" result="shape" />
        </filter>
        <filter
          id="filter1_d_3_12"
          x="70.7511"
          y="10.7084"
          width="16.2537"
          height="41.2513"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_12" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_12" result="shape" />
        </filter>
        <filter
          id="filter2_d_3_12"
          x="81.0816"
          y="10.0473"
          width="23.9091"
          height="41.9125"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_12" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_12" result="shape" />
        </filter>
        <filter
          id="filter3_d_3_12"
          x="97.9194"
          y="17.1983"
          width="29.0835"
          height="35.1104"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_12" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_12" result="shape" />
        </filter>
        <filter
          id="filter4_d_3_12"
          x="0"
          y="5"
          width="51.8828"
          height="50"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_12" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_12" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default Logo
