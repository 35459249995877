export const FACTORY_ADDRESS = '0xe236f6890f1824fa0a7ffc39b1597a5a6077cfe9'

export const BUNDLE_ID = '1'

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  // THREE_MONTHS: '3 months',
  // YEAR: '1 year',
  HALF_YEAR: '6 months',
  ALL_TIME: 'All time',
}

// token list urls to fetch tokens from - use for warnings on tokens and pairs
export const SUPPORTED_LIST_URLS__NO_ENS = ['https://www.coingecko.com/tokens_list/uniswap/defi_100/v_0_0_0.json']

// hide from overview list
export const TOKEN_BLACKLIST = [
  '0x6eec4ed9564b90e3b16aa72aece22bbb4c2ccb66',
  '0xc2b9a4775626584cfdedd8987980333f3a1c274f',
  '0x99629a6b918a693796a2f1a9a777f49c77602442',
  '0x80514a0406da411a01c953ee863517e5903abcce',
  '0x605c79b5c60a9d4675c87146d57d275624335381',
  // '0x8e11ff9a74ae97b295e14f8d9d48e3a3d72ce890', //BAEP
  // '0x2adc50b801930ea2bed1504c566f1996b3676ab4', //TestERC20
  // '0x94a7f270cd12545a277e656266aef5e27df3eb28', //Fantom-Peg Stream
  // '0xdc301622e621166bd8e82f2ca0a26c13ad0be355', //Frax
  // '0x1bfb49e77acbca1b15fb69fa061c71291c31dce5', //noSolidex
  // '0x7d016eec9c25232b01f23ef992d98ca97fc2af5a', //Frax Share FXS
  // '0x47afacadc6773dc4b8177bc7ac3dd96383800380', //Covenant (COVT)
  // '0x93aba1e8a602984e7b6a4ba93061806185e7a953', //TeraCoin (TAO)
  // '0xda763530614fb51dff9673232c8b3b3e0a67bcf2', //DANTE (DANTE)
  // '0x94846cde220f426f4a1a7d6a133be420873751d8', //Libtard
  // '0xd2f783aac3dfd5478a2e2e459c0b48b63796b76b', //TestShareERC20
  // '0x68aa691a8819b07988b18923f712f3f4c8d36346', //Qi
  // '0x5b194e55032f55030672c670f279e10175a4912d', //BAEP (BAEP)
  // '0xffcaabdbc66c815b789551877f120e8aee007f5f', //BAEP
  // '0xef88ef809882064d040af1bd66a3cb809cd25d61', //ALT
]

// pair blacklist
export const PAIR_BLACKLIST = []

// warnings to display if page contains info about blocked token
export const BLOCKED_WARNINGS = {}

/**
 * For tokens that cause erros on fee calculations
 */
export const FEE_WARNING_TOKENS = []

export const UNTRACKED_COPY = 'Derived USD values may be inaccurate without liquid stablecoin or Tomb pairings.'

// tokens that should be tracked but arent due to lag in subgraph
export const TRACKED_OVERRIDES = [
  '0x26519b547416e4f53f3a4b05b95ef859c3bd89fe',
  '0xf6c7197eaff13002a7b2a2e835a7bb6969a4b026',
  '0xd77fc9c4074b56Ecf80009744391942FBFDDd88b',
]
