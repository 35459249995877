import React from 'react'

export const MasonryIcon: React.FC = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.79732 8.20277L4.04443 14L7.15554 12.091L10.2667 14L9.51377 8.19641" fill="white" fillOpacity="0.4" />
      <path
        d="M7.1556 8.90909C9.56111 8.90909 11.5112 6.91472 11.5112 4.45455C11.5112 1.99437 9.56111 0 7.1556 0C4.7501 0 2.80005 1.99437 2.80005 4.45455C2.80005 6.91472 4.7501 8.90909 7.1556 8.90909Z"
        fill="currentColor"
      />
    </svg>
  )
}
