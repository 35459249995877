import React, { useState } from 'react'
import styled from 'styled-components'
import { AutoColumn } from '../Column'
import Title from './Title'
import { Link as RouterLink } from 'react-router-dom'
import { BasicLink } from '../Link'
import { useMedia, useLockBodyScroll } from 'react-use'
import { withRouter } from 'react-router-dom'
import Link from '../Link'
import { useSessionStart } from '../../contexts/Application'
import { useDarkModeManager } from '../../contexts/LocalStorage'
import Toggle from '../Toggle'
import Tokens from '../Icons/Tokens.js'
import Pairs from '../Icons/Pairs.js'
import Overview from '../Icons/Overview.js'
import { TYPE } from '../../Theme'
import { Search, ArrowLeft } from 'react-feather'
import SearchInput from '../Search/index'

const Wrapper = styled.div`
  height: ${({ isMobile }) => (isMobile ? 'initial' : '100vh')};
  color: ${({ theme }) => theme.text1};
  padding: 10px 20px;
  position: sticky;
  top: 0px;
  z-index: 200;
  box-sizing: border-box;
  color: ${({ theme }) => theme.bg2};

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    position: relative;
    /* margin-top: 36px; */
    /* margin-bottom: 24px; */
    padding: 0 10px;
    margin: 0;
  }
`

const Option = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme, activeText }) => (activeText ? theme.green : theme.textColor)};
  display: flex;
  transition: color 0.3s;

  :hover {
    color: rgba(#ffffff, 0.8);
  }
`

const DesktopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 54px);
  /* margin-top: 54px; */

  @media screen and (max-width: 800px) {
    margin-top: 36px;
    height: calc(100% - 36px);
  }
`

const MobileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const HeaderText = styled.div`
  margin-right: 0.75rem;
  font-size: 14px;
  display: inline-box;
  display: -webkit-inline-box;
  transition: opacity 0.3s;

  :hover {
    opacity: 0.8;
  }

  a {
    color: ${({ theme }) => theme.textColor};
  }
`

const Polling = styled.div`
  position: fixed;
  display: flex;
  padding: 20px 48px;

  left: 0;
  bottom: 0;
  padding: 1rem;
  color: white;
  opacity: 0.4;
  transition: opacity 0.25s ease;
  :hover {
    opacity: 1;
  }
`
const PollingDot = styled.div`
  width: 8px;
  height: 8px;
  min-height: 8px;
  min-width: 8px;
  margin-right: 0.5rem;
  margin-top: 3px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.green};
`

const MobileFixedNav = styled.nav`
  position: fixed;
  bottom: 32px;
  left: 50%;

  transform: translateX(-50%);

  width: calc(100% - 20px);
  max-width: 355px;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.panelColor};
  backdrop-filter: blur(10px);
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.14), 0px 10.125px 17.4375px rgba(0, 0, 0, 0.0945);
  border-radius: 32px;
`

const MobileLink = styled(RouterLink)`
  position: relative;
  text-decoration: none;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;

  &::after {
    position: absolute;
    right: 0;
    top: 14px;

    width: 1px;
    height: 36px;
    background-color: 'rgba(255, 255, 255, 0.1)';
    content: '';
  }
`

const MobileBtn = styled.button`
  position: relative;
  text-decoration: none;
  padding: 20px 0;
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 0;
`

const UpdatedLink = styled.a`
  color: ${({ theme }) => theme.textColor};
`

const SearchBtn = styled.button`
  display: block;
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.textColor};
  background: transparent;
  padding: 0;
  border: 0;

  &:hover {
    opacity: 0.8;
  }
`

const SearchBox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 9999;
  padding: 35px 10px 60px 10px;
  display: flex;
  flex-direction: column;
  background: rgba(0, 7, 30, 0.6);
  backdrop-filter: blur(15px);

  display: ${({ hide }) => hide && 'none'};
`

const MenuBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 3.75rem;
  border-radius: 20px;
  padding: 20px;
  backdrop-filter: blur(15px);
  background: ${({ theme }) => theme.advancedBG};
`

function SideNav({ history }) {
  const below1080 = useMedia('(max-width: 1080px)')

  const below1180 = useMedia('(max-width: 1180px)')

  const seconds = useSessionStart()

  const [isDark, toggleDarkMode] = useDarkModeManager()
  const [showSearch, setShowSearh] = useState(false)

  useLockBodyScroll(showSearch)

  return (
    <Wrapper isMobile={below1080}>
      {!below1080 ? (
        <DesktopWrapper>
          <AutoColumn gap="1rem">
            {/* <Title /> */}
            {!below1080 && (
              <MenuBox>
                {/* <Toggle isActive={isDark} toggle={toggleDarkMode} /> */}
                <BasicLink to="/home">
                  <Option activeText={history.location.pathname === '/home' ?? undefined}>
                    <Overview style={{ marginRight: '.75rem' }} />
                    Overview
                  </Option>
                </BasicLink>
                <BasicLink to="/tokens">
                  <Option
                    activeText={
                      (history.location.pathname.split('/')[1] === 'tokens' ||
                        history.location.pathname.split('/')[1] === 'token') ??
                      undefined
                    }
                  >
                    <Tokens style={{ marginRight: '.75rem' }} />
                    Tokens
                  </Option>
                </BasicLink>
                <BasicLink to="/pairs">
                  <Option
                    activeText={
                      (history.location.pathname.split('/')[1] === 'pairs' ||
                        history.location.pathname.split('/')[1] === 'pair') ??
                      undefined
                    }
                  >
                    <Pairs style={{ marginRight: '.75rem' }} />
                    Pairs
                  </Option>
                </BasicLink>
              </MenuBox>
            )}
          </AutoColumn>
          <AutoColumn gap="0.5rem" style={{ marginLeft: '.75rem', marginBottom: '200px' }}>
            {/* <HeaderText>
              <Link href="https://lif3.com/" target="_blank">
                LIF3
              </Link>
            </HeaderText> */}
            {/* <HeaderText>
              <Link href="https://docs.lif3.com/" target="_blank">
                Docs
              </Link>
            </HeaderText> */}
            {/* <HeaderText>
              <Link href="https://discord.gg/vANnESmVdz" target="_blank">
                Discord
              </Link>
            </HeaderText> */}
            {/* <HeaderText>
              <Link href="https://twitter.com/Official_LIF3" target="_blank">
                Twitter
              </Link>
            </HeaderText> */}
          </AutoColumn>
          {!below1180 && (
            <Polling style={{ marginLeft: '.5rem' }}>
              <PollingDot />
              <UpdatedLink href="/">
                <TYPE.small color={'inherit'}>
                  Updated {!!seconds ? seconds + 's' : '-'} ago <br />
                </TYPE.small>
              </UpdatedLink>
            </Polling>
          )}
        </DesktopWrapper>
      ) : (
        <MobileWrapper>
          {/* <Title /> */}
          <MobileFixedNav>
            <MobileLink to="/home">
              <Option activeText={history.location.pathname === '/home' ?? undefined}>
                <Overview />
              </Option>
            </MobileLink>
            <MobileLink to="/tokens">
              <Option
                activeText={
                  (history.location.pathname.split('/')[1] === 'tokens' ||
                    history.location.pathname.split('/')[1] === 'token') ??
                  undefined
                }
              >
                <Tokens />
              </Option>
            </MobileLink>
            <MobileLink to="/pairs">
              <Option
                activeText={
                  (history.location.pathname.split('/')[1] === 'pairs' ||
                    history.location.pathname.split('/')[1] === 'pair') ??
                  undefined
                }
              >
                <Pairs />
              </Option>
            </MobileLink>
            <MobileBtn
              onClick={() => {
                setShowSearh(true)
              }}
            >
              <Option>
                <Search size={22} />
              </Option>
            </MobileBtn>
          </MobileFixedNav>
          <SearchBox hide={!showSearch}>
            <SearchBtn
              onClick={() => {
                setShowSearh(false)
              }}
            >
              <ArrowLeft size={20} />
            </SearchBtn>
            <SearchInput isInModal={true} setShowSearch={setShowSearh} />
          </SearchBox>
        </MobileWrapper>
      )}
    </Wrapper>
  )
}
export default withRouter(SideNav)
/*

                <BasicLink to="/accounts">
                  <Option
                    activeText={
                      (history.location.pathname.split('/')[1] === 'accounts' ||
                        history.location.pathname.split('/')[1] === 'account') ??
                      undefined
                    }
                  >
                    <List size={20} style={{ marginRight: '.75rem' }} />
                    Accounts
                  </Option>
                </BasicLink>
                */
