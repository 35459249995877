import React from 'react'

export const WalletIcon: React.FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 8L15 2L18 8M1 6.5V18C1 19.1046 1.8954 20 3 20H19V8H2.5C1.6716 8 1 7.3284 1 6.5ZM1 6.5C1 5.6716 1.6716 5 2.5 5H9L1 6.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M19 14H15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeDasharray="0.01 4.5" />
    </svg>
  )
}
