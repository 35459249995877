import React from 'react'

export const GenesisIcon: React.FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.6939 0.334478C5.56463 0.464401 5.46971 0.622392 5.41507 0.794218L1.91264 10.1234H0.5V11.5542H3.17538L4.20633 8.70888L4.69697 7.27848L6.48054 2.21346L9.76987 11.5542H12.5V10.1234H11.0819L7.57973 0.79392C7.52508 0.622243 7.43017 0.464302 7.30094 0.334478C7.08785 0.120303 6.7988 0 6.49745 0C6.19604 0 5.90704 0.120303 5.6939 0.334478Z"
        fill="currentColor"
      />
    </svg>
  )
}
