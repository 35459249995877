import React from 'react'

export const StakeIcon: React.FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.47341 9.25996L4.66675 15.3333L8.00008 13.3333L11.3334 15.3333L10.5267 9.2533"
        fill="currentColor"
        fillOpacity="0.4"
      />
      <path
        d="M7.99992 9.99996C10.5772 9.99996 12.6666 7.91062 12.6666 5.33329C12.6666 2.75596 10.5772 0.666626 7.99992 0.666626C5.42259 0.666626 3.33325 2.75596 3.33325 5.33329C3.33325 7.91062 5.42259 9.99996 7.99992 9.99996Z"
        fill="currentColor"
      />
    </svg>
  )
}
